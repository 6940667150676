<template>
  <component :is="isDialog?'q-dialog':'div'" id="sale-list" v-model="isDialogOpen" @hide="cancel">
    <div>
      <ListTable ref="tableRef" id="sale-table" slim :tabOptions="tabOptions" :columns="columns" :fetchAsync="fetchSales" dataName="sales" :selectable="selectable" search="orderNo,trackNo,details,tags,amount,items.name">
        <!-- Add button -->
        <template #add>
          <q-btn v-if="$can('create','Sale')" push no-caps color="primary" @click.stop="saleEditRef.open().then(v=>v?tableRef.refetchTable():'')">
            <span class="text-nowrap text-capitalize">{{$t('action.add')}}</span>
          </q-btn>
        </template>

        <template #head(orderNo)>
          <fa-icon icon="store" class="text-primary mr-50"/> <span class="text-capitalize">{{$t('form.order_number')}}</span>
        </template>
        <template #head(customer.name)>
          <fa-icon icon="user" class="text-primary mr-50"/> <span class="text-capitalize">{{$t('member.customer._')}}</span>
        </template>

        <!-- Order number -->
        <template #cell(orderNo)="{ item }">
          <q-btn flat padding="0.25rem 0.50rem" @click.stop="saleViewRef.open(item._id).then(v=>v?tableRef.refetchTable():'')" class="w-100 rounded-lg">
            <div class="flex-grow-1 d-flex justify-content-between align-items-center">
              <div class="flex gap-2 align-items-center flex-nowrap">
                <Avatar :url="item.files"/>
                <div class="text-left">
                  <!-- Name -->
                  <div class="text-nowrap" :class="item.deleted?'text-danger':'text-primary'">{{ item.orderNo }}</div>
                  <!-- Status -->
                  <q-chip v-if="item.status" size="sm" :color="`light-${$t(`status.${item.status}.color`)}`">
                    <q-avatar :color="$t(`status.${item.status}.color`)" text-color="white">
                      <fa-icon fixed-width :icon="['far',$t(`status.${item.status}.icon`)]" />
                    </q-avatar>
                    <span class="text-capitalize text-nowrap d-none d-sm-inline">{{$t(`status.${item.status}._`)}}</span>
                  </q-chip>
                </div>
              </div>
              <fa-icon :icon="['far','chevron-right']" class="text-primary ml-1"/>
            </div>
          </q-btn>
        </template>

        <!-- Customer -->
        <template #cell(customer.name)="{ item }">
          <div @click.stop="customerViewRef.open(item.customer._id)" class="d-flex w-100 justify-content-between align-items-center">
            <div class="d-flex w-100 flex-column gap-50 align-items-start">
              <div class="d-flex gap-1 align-items-center">
                {{ item.customer.name }}
              </div>
              <!-- Status -->
              <q-chip v-if="item.status" size="sm" :color="`light-${item.customer.grade.color}`">
                <span class="text-capitalize text-nowrap d-none d-sm-inline">{{item.customer.grade.names | name}}</span>
              </q-chip>
            </div>
            <fa-icon :icon="['far','chevron-right']" class="text-primary ml-1 mr-50"/>
          </div>
        </template>

        <!-- Total amount -->
        <template #cell(total)="{ item }">
          <div class="d-flex flex-column gap-50">
            <div class="text-nowrap" :class="item.total<0?'text-danger':'text-primary'">
              {{item.total||0 | price($t(`currency.${item.currency}.sign`))}} 
              <q-avatar size="xs" :color="item.total===item.paidAmount?'success':(item.total>item.paidAmount?'warning':'danger')" text-color="white">
                <fa-icon fixed-width :icon="['far',item.total===item.paidAmount?'check':(item.total>item.paidAmount?'circle':'times')]" />
              </q-avatar>
            </div>
            <div class="d-flex gap-1">
              <q-btn no-caps size="sm" dense :to="{name:'sale-invoice-view', params:{id:item._id} }" push color="primary">{{$t('supply-chain.invoice._') | capitalize}}</q-btn>
              <q-btn no-caps size="sm" dense v-if="item.paidAmount<item.total" push color="warning"
                @click.stop="payDialogRef.openDialog({saleId:item._id, customerId:item.customer._id}).then(v=>v===true?refetchData():'')">{{$t('finance.action.pay')}}</q-btn>
            </div>
          </div>
        </template>

        <!-- Variants -->
        <template #cell(variants)="{ item }">
          <div v-for="item in item.variants.filter(t=>!t.isPayment)" :key="item._id" class="d-flex gap-1 justify-content-between align-items-center">
            <div class="d-flex gap-1 align-items-center flex-nowarp">
              <q-chip size="sm"  class="flex-nowrap">
                <Avatar :url="item.files" size="sm" :color="item.isInventory?'primary':'danger'" iconClass="text-white"/>
                {{item.productNames | name}} - {{item.names | name}}  
              </q-chip>
            </div>
            <span class="text-primary text-nowrap">x {{item.quantity}}</span>
          </div>
        </template>

        <!-- Column: Address -->
        <template #cell(address.zip)="{item}">
          <div class="d-flex flex-column gap-50 align-items-center">
            <div class="text-nowrap">{{item.address.unit}}</div>
            <q-chip size="sm" color="light-primary" class="text-nowrap text-primary">{{item.address.zip}}</q-chip>
            <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
              {{item.address.address}}
            </q-tooltip>
          </div>
        </template>

        <!-- Tags -->
        <template #cell(tags)="{ item }">
          <div class="d-flex flex-wrap">
            <q-chip v-for="(tag, index) in item.tags" size="sm" color="warning" text-color="white" :key="tag.index" class="mx-25">{{tag}}</q-chip>
          </div>
        </template>

        <!-- Date -->
        <template #cell(createdAt)="{ item }">
          <div class="text-left">{{item.createdAt | date}}</div>
          <div class="text-left">{{item.createdAt | time}}</div>
        </template>

        <!-- Actions -->
        <template #cell(actions)="{ item }">
          <div v-if="!item.deleted" class="text-nowrap d-flex gap-1 align-items-center justify-content-center">
            <!-- Approve -->
            <q-btn v-if="$can('approve','Sale') && item.approvalStatus==='open'" push color="warning" @click.stop="approveSale(item._id)">
              <feather-icon icon="UserCheckIcon" size="16" class="mr-50"/>{{capitalize($t('action.approve'))}}
            </q-btn>
            <!-- Pay -->
            <q-btn v-else-if="$can('create','Finance') && item.approvalStatus==='approved'" push no-caps color="warning" @click.stop="openPayDialog(item._id)">
              <fa-icon :icon="['far','sack-dollar']" class="mr-50"/>{{capitalize($t('form.journal'))}}
            </q-btn>
            <!-- Check in -->
            <q-btn v-else-if="$can('create','Inventory') && item.approvalStatus==='journaled'" push no-caps color="success" @click.stop="checkInOrder(item._id)">
              <fa-icon :icon="['far','check']" class="mr-50"/>{{capitalize($t('form.check_in'))}}
            </q-btn>

            <!-- Packing -->
            <q-btn v-if="item.isPaid && !item.isFulfilled" push color="warning" @click.stop="packingSidebarRef.open(item._id)">
              <fa-icon :icon="['far', 'box-full']" class="my-50" />
            </q-btn>

            <q-btn v-if="$can('update','Sale') && item.approvalStatus!=='fulfilled'" flat round @click.stop="saleEditRef.open(item._id).then(v=>v?tableRef.refetchTable():'')">
              <feather-icon icon="EditIcon" class="text-primary cursor-pointer" size="16"/>
            </q-btn>
            <q-btn v-if="$can('delete','Sale') && !item.deleted && !item.isRewarded && !item.isPaid" flat round @click="deleteSale(item._id).then(v=>v?tableRef.refetchTable():'')" >
              <feather-icon icon="TrashIcon" class="text-danger cursor-pointer" size="16"/>
            </q-btn>
            
          </div>
        </template>
      </ListTable>
    </div>
    <SaleView ref="saleViewRef"/>
    <SaleEdit ref="saleEditRef"/>
    <CustomerView ref="customerViewRef"/>
    <PackingSidebar ref="packingSidebarRef"/>
    <!-- Dialog -->
    <PayDialog ref="payDialogRef"/> 
  </component>
</template>


<script>
import { ref, watch } from '@vue/composition-api'
import { price, date, time, capitalize, mmdd, i18n, name } from '@core/utils/filter'
import store from '@/store'
import useSale from './useSale'
import FileGallery from '@core/components/file-gallery/FileGallery'
import Avatar from '@core/components/Avatar'
import ListTable from '@core/components/ListTable'
import SaleView from './SaleView'
import SaleEdit from './SaleEdit'
import CustomerView from '../../member/customer/CustomerView'
import PackingSidebar from './PackingSidebar'
import PayDialog from './components/PayDialog'

export default {
  name: 'sale-list',
  components: {
    FileGallery, Avatar, ListTable,
    SaleView, SaleEdit,
    CustomerView,
    PackingSidebar,
    PayDialog,
  },
  props:{
    isDialog: Boolean,
    showIcon: Boolean,
    selectable: Boolean,
    filters: {
      type: Object,
      default: ()=>{}
    },
    sort: String
  },
  filters: {
    price, capitalize, date, time, mmdd, name
  },
  setup() {
    // Ref
    const saleViewRef = ref(null)
    const saleEditRef = ref(null)
    const customerViewRef = ref(null)
    const packingSidebarRef = ref(null)
    const payDialogRef = ref(null)

    const { deleteSale } = useSale()

    // Table
    const tableRef = ref(null)
    const columns = ref([
      { key: 'saleSeq', label: '#', noFilter: true, sortable: true, width:'20px'},
      { key: 'orderNo', sortable: true },
      { key: 'customer.name', sortable: true },
      { key: 'total', label:i18n('form.amount'), isNumber:true, sortable: true},
      { key: 'variants', label:i18n('form.variants'), noFilter: true},
      { key: 'address.zip', label:i18n('action.address'), searchLabel:i18n('form.zip'), sortable: true},
      { key: 'tags', label:i18n('form.tags')},
      { key: 'createdAt', label:i18n('form.date'), isDateRange: true, sortable: true },
      { key: 'actions', label:i18n('table.action'), noFilter: true }
    ])

    // Action
    const fetchSales = params => store.dispatch('sale/fetchSales', params)

    // Dialog view
    const isDialogOpen = ref(false)

    // Tabs
    const tabOptions = ref([{key:'all', value: ''}].concat(['new','paying','reviewing','preparing','packing','delivering','fulfilled'].map(s=>({key:s, value: {status:s}}))))
    
    let cancelAction = () => isDialogOpen.value = false
    const cancel = ()=>cancelAction()
    const selectOne = ()=>new Promise(res => {
      // 隐藏column
      columns.value = columns.value.filter(c=>c.key!=='actions')
      isDialogOpen.value = true
      cancelAction = ()=>{
        isDialogOpen.value = false
        res(false)
      }
      watch(tableRef, ref=>ref?.clickRow().then(val=>{
        isDialogOpen.value = false
        res(val)
      }))
    })

    return {
      // Dialog View
      isDialogOpen,
      cancel,
      selectOne,
      
      // Table
      columns,
      tableRef,
      tabOptions,

      // Action
      fetchSales,
      deleteSale,

      // Refs
      saleViewRef,
      saleEditRef,
      customerViewRef,
      packingSidebarRef,
      payDialogRef
    }
  }
}
</script>
<style lang="scss" scope>
#sale-table {
  td:first-child {
    max-width: 120px
  }
}
</style>