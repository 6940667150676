<template>
  <BSidebar id="packing-sidebar" right sidebar-class="sidebar-lg" shadow backdrop :width="sidebarWidth" header-class="p-0" bg-variant="white"
    :visible="isSidebarActive" @hidden="close()">
    <template #header="{ hide }"><!-- Header -->
      <div class="d-flex flex-column w-100">
        <div class="w-100 d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 bg-white border-bottom shadow-sm z-index-10">
          <div v-if="saleData" class="flex gap-3 align-items-center text-primary">
            <fa-icon :icon="['fad', $t('supply-chain.status.packing.icon')]" size="2x"/>
            <div class="flex flex-column">
              <span class="text-capitalize">{{$t('supply-chain.packing._')}}</span>
              <span class="m-0 h5">{{saleData.orderNo}}</span>
            </div>
          </div>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="close"/>
        </div>
        <q-tabs v-model="tab" class="border-bottom bg-light-secondary inner-shadow" active-color="primary" indicator-color="primary" align="justify" narrow-indicator>
          <q-tab name="pick" icon="fad fa-scanner" :label="$t('supply-chain.process.pick')" class="pt-50" content-class="gap-50"/>
          <q-tab name="pack" icon="fad fa-box" :label="$t('supply-chain.process.pack')" class="pt-50" content-class="gap-50"/>
          <q-tab name="track" icon="fad fa-truck" :label="$t('supply-chain.process.track')" class="pt-50" content-class="gap-50"/>
        </q-tabs>
      </div>
    </template>

    <template #default><!-- BODY -->
      <q-tab-panels v-model="tab" animated transition-prev="jump-left" transition-next="jump-right" class="h-100 bg-light-secondary">
        <!-- Pick -->
        <q-tab-panel name="pick" class="p-0">
          <q-scroll-area class="h-100 overflow-hidden" delay='600' :thumb-style="{ right: '4px', borderRadius: '5px', backgroundColor: '#7367f0', width: '5px', opacity: 0.6 }"
            :bar-style="{right: '2px', borderRadius: '9px', backgroundColor: '#7367f0', width: '9px', background: 'dark', opacity: 0.2}">
            <BTable class="position-relative shadow mb-0 text-center bg-white" responsive show-empty hover primary-key="_id"
              :items="saleData.variants" :fields="[
                {key:'name',label:$t('form.name')}, 
                {key:'quantity',label:$t('form.quantity')}, 
                {key:'amount',label:$t('form.amount')},
                {key:'isPacked',label:$t('supply-chain.inventory.packed')}]">
              <!-- Name -->
              <template #cell(name)="{ item }">
                <div class="d-flex gap-2 align-items-center">
                  <Avatar :url="item.files" defaultIcon="user" />
                  <div v-if="item.names" class="text-nowrap text-left">
                    <div class="m-0 h5" :class="item.isInventory?'text-primary':'text-danger'">{{item.productNames | name}}</div>
                    <div>{{item.names | name}}</div>
                  </div>
                </div>
              </template>

              <!-- Amount -->
              <template #cell(amount)="{ item }">
                <div class="text-nowrap d-flex justify-content-center align-items-center" :class="item.amount<0?'text-danger':'text-primary'">
                  {{Math.abs(item.amount||0) | price($t(`currency.${item.currency}.sign`))}}
                </div>
              </template>

              <!-- isPacked -->
              <template #cell(isPacked)="{ item }">
                <q-btn push :color="item.isPacked?'success':'secondary'" @click.stop="packSaleVariant(item._id, !item.isPacked, item)" :loading="item.loading">
                  <fa-icon :icon="item.isPacked?'check':'times'" class="my-50" fixed-width/>
                </q-btn>
              </template>
            </BTable>
          </q-scroll-area>
        </q-tab-panel>

        <!-- pack -->
        <q-tab-panel name="pack" class="p-0 d-flex flex-column">
          <q-scroll-area class="flex-grow-1 overflow-hidden" delay='600' :thumb-style="{ right: '4px', borderRadius: '5px', backgroundColor: '#7367f0', width: '5px', opacity: 0.6 }"
            :bar-style="{right: '2px', borderRadius: '9px', backgroundColor: '#7367f0', width: '9px', background: 'dark', opacity: 0.2}">
            <div v-if="saleData">
              <q-list separator class="shadow bg-white">
                <q-item v-if="!packages || packages.length===0" key="-1" class="p-0">
                  <q-item-section class="bg-light-secondary rounded-lg align-items-center"><fa-icon :icon="['fad','cauldron']" size="5x" class="text-secondary m-2"/></q-item-section>
                </q-item>
                <q-item class="py-50 px-1" clickable v-for="p in packages" :key="p._id">
                  <q-item-section avatar><q-avatar><fa-icon :icon="['fad',p.quantity>1?'boxes':'box']" class="text-warning" /></q-avatar></q-item-section>
                  <q-item-section class="d-none d-sm-flex flex-size-1">
                    <q-item-label v-if="p.size && p.size.length>0 && p.volume" class="d-flex align-items-center gap-1">
                      <fa-icon icon="ruler" class="text-primary"/>
                      {{p.size[0]}} x {{p.size[1]}} x {{p.size[2]}} {{$global.default_size}}
                    </q-item-label>
                  </q-item-section>

                  <q-item-section>
                    <q-item-label v-if="p.volume" class="d-flex align-items-center gap-1">
                      <fa-icon icon="cube" class="text-primary"/>
                      {{p.volume}} {{$global.default_volume}}
                    </q-item-label>
                  </q-item-section>

                  <q-item-section>
                    <q-item-label v-if="p.weight" class="d-flex align-items-center gap-1">
                      <fa-icon icon="weight-hanging" class="text-primary"/>
                      {{p.weight}} {{$global.default_weight}}
                    </q-item-label>
                  </q-item-section>

                  <q-item-section>
                    <q-item-label v-if="p.quantity" class="d-flex align-items-center gap-1">
                      <fa-icon icon="times" class="text-danger"/>{{p.quantity}}
                    </q-item-label>
                  </q-item-section>
                  
                  <q-item-section side class="d-flex gap-1 flex-row align-items-center">
                    <q-btn push color="danger" @click.stop="removePackage(saleData._id, p._id)"><fa-icon fixed-width icon="trash" class="my-50"/></q-btn>
                  </q-item-section>
                </q-item>
              </q-list>

              <!-- 新增 -->
              <q-form class="d-flex gap-3 p-1 rounded-lg border shadow m-2 bg-white" @submit.prevent="addPackage(newPackage)">
                <!-- Size -->
                <div class="d-flex flex-size-4 gap-1">
                  <q-input type="number" filled dense hide-bottom-space v-model="newPackage.size[0]" :label="$t('size.length')|capitalize"
                    class="flex-even m-0 left" :rules="[val => !val || val>=0 || $t('rule.positive')]" @input="val=>newPackage.size[0]=toNumberCeil(val, 0)">
                    <template #append><small class="text-bold text-primary mr-25 d-none d-sm-inline">{{$global.default_size}}</small></template>
                  </q-input>
                  <q-input type="number" filled square dense hide-bottom-space v-model="newPackage.size[1]" :label="$t('size.width')|capitalize"
                    class="flex-even m-0" :rules="[val => !val || val>=0 || $t('rule.positive')]" @input="val=>newPackage.size[1]=toNumberCeil(val, 0)">
                    <template #append><small class="text-bold text-primary mr-25 d-none d-sm-inline">{{$global.default_size}}</small></template>
                  </q-input>
                  <q-input type="number" filled dense hide-bottom-space v-model="newPackage.size[2]" :label="$t('size.height')|capitalize"
                    class="flex-even m-0 right" :rules="[val => !val || val>=0 || $t('rule.positive')]" @input="val=>newPackage.size[2]=toNumberCeil(val, 0)">
                    <template #append><small class="text-bold text-primary mr-25 d-none d-sm-inline">{{$global.default_size}}</small></template>
                  </q-input>
                </div>
                <!-- Weight -->
                <q-input type="number" filled dense hide-bottom-space v-model="newPackage.weight" :label="$t('form.weight')|capitalize" step="0.01"
                  class="flex-even flex-size-2 m-0" :rules="[val=>!val || val>=0 || $t('rule.positive')]" @input="val=>newPackage.weight=toNumberCeil(val)">
                  <template #prepend><fa-icon :icon="['far','weight-hanging']" fixed-width class="text-primary mr-25"/></template>
                  <template #append><small class="text-bold text-primary mr-25">{{$global.default_weight}}</small></template>
                </q-input>
                <!-- Quantity -->
                <q-input type="number" filled dense hide-bottom-space v-model="newPackage.quantity" :label="$t('form.quantity')|capitalize"
                  class="flex-even flex-size-2 m-0" :rules="[val=>val>0 || $t('rule.positive')]" @input="val=>newPackage.quantity=toNumberCeil(val)">
                  <template #prepend><fa-icon :icon="['far','times']" fixed-width class="text-danger mr-25"/></template>
                </q-input>
                <!-- Button -->
                <q-btn push color="primary" type="submit"><fa-icon fixed-width icon="plus" class="my-50"/></q-btn>
              </q-form>
            </div>
          </q-scroll-area>
          <!-- 总计 -->
          <q-item class="py-2 px-1 h4 m-0 bg-white shadow border" clickable>
            <q-item-section class="text-capitalize text-center">{{$t('form.total')}}</q-item-section>
            <q-item-section>
              <q-item-label v-if="saleData.packing" class="d-flex justify-content-center align-items-center gap-1">
                <fa-icon icon="cube" class="text-primary"/>
                {{saleData.packing.volume}} {{$global.default_volume}}
              </q-item-label>
            </q-item-section>

            <q-item-section>
              <q-item-label v-if="saleData.packing" class="d-flex justify-content-center align-items-center gap-1">
                <fa-icon icon="weight-hanging" class="text-primary"/>
                {{saleData.packing.weight}} {{$global.default_weight}}
              </q-item-label>
            </q-item-section>

            <q-item-section>
              <q-item-label v-if="saleData.packing" class="d-flex justify-content-center align-items-center gap-1">
                <fa-icon icon="times" class="text-danger"/> {{saleData.packing.quantity}}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-tab-panel>

        <!-- Track -->
        <q-tab-panel name="track" class="p-0 overflow-hidden">
          <q-scroll-area class="h-100" delay='600'
            :thumb-style="{ right: '4px', borderRadius: '5px', backgroundColor: '#7367f0', width: '5px', opacity: 0.6 }"
            :bar-style="{right: '2px', borderRadius: '9px', backgroundColor: '#7367f0', width: '9px', background: 'dark', opacity: 0.2}">
            <Tracking ref="trackRef" :data="saleData.tracks" :funcAdd="addTrack" :funcRemove="removeTrack" update />
          </q-scroll-area>
        </q-tab-panel>
      </q-tab-panels>
      <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </template>
  </BSidebar>
</template>

<script>
import { ref, watch, set } from '@vue/composition-api'
import { BSidebar, BTable } from 'bootstrap-vue'
import { Toast } from '@core/utils/other'
import { date, capitalize, price, groupingNumber, getColor, fileUrl, toNumberCeil, name } from '@core/utils/filter'
import store from '@/store'
import useSale from './useSale'
import Avatar from '@core/components/Avatar'
import FileGallery from '@core/components/file-gallery/FileGallery'
import Tracking from '@core/components/Tracking'
export default {
  name: 'packing-sidebar',
  components: {
    BSidebar, BTable,
    Avatar, FileGallery,
    Tracking
  },
  filters: {
    date, capitalize, price, groupingNumber, name
  },
  setup(){
    const sidebarWidth = ref('800px')
    const trackRef = ref(null)
    const {
      tt, loading, saleData,
      resetSale, fetchSale
    } = useSale()

    // Tabs
    const tab = ref('pick')
    watch(tab, val=>{
      if (val==='pick') sidebarWidth.value = '800px'
      if (val==='pack') sidebarWidth.value = '800px'
      if (val==='track') sidebarWidth.value = '800px'
    })

    // items
    const packages = ref([])
    const newPackage = ref({
      size: [0,0,0],
      volume:0,
      weight:0,
      quantity:1
    })
    const addPackage = p => {
      store.dispatch('sale/addPackage', {id:saleData.value._id, p}).then(response=>{
        saleData.value.packing = response.packing
        packages.value = response.packing.packages
        Toast.fire({icon:'success', title:i18n('action.added')})
      })
    }

    const removePackage = (saleId, packageId) => {
      store.dispatch('sale/removePackage', {saleId, packageId}).then(response=>{
        saleData.value.packing = response.packing
        packages.value = response.packing.packages
        Toast.fire({icon:'success', title:i18n('action.removed')})
      }).catch(e=>Toast.fire({icon:'error', title:e.message}))
    }

    // Sidebar
    const isSidebarActive = ref(false)
    const open = id=>{
      loading.value = true
      isSidebarActive.value = true
      fetchSale(id, sale=>{
        packages.value = []
        if (sale.packing && sale.packing.packages && sale.packing.packages.length>0) packages.value = sale.packing.packages
        loading.value = false
      })
    }
    const close = ()=>{
      isSidebarActive.value = false
      resetSale()
    }

    // Packing
    const packSaleVariant = (id, isPacked, variantObj)=>{
      set(variantObj, 'loading', true)
      store.dispatch('sale/packSaleVariant', {id, isPacked}).then(response=>{
        variantObj.isPacked = response.isPacked
        variantObj.loading = false
      }).catch(e=>{
        Toast.fire({ icon: 'error', title: e.message })
        variantObj.loading = false
      })
    }

    // Track
    const addTrack = track => store.dispatch('sale/addTrack', {saleId: saleData.value._id, track}).then(r=> saleData.value.tracks = r.tracks)
    const removeTrack = trackId => store.dispatch('sale/removeTrack', {saleId: saleData.value._id, trackId}).then(r=> saleData.value.tracks = r.tracks)

    return {
      loading,
      capitalize, getColor, fileUrl, toNumberCeil,
      Toast,

      // Sidebar
      isSidebarActive,
      open,
      close,

      // packing
      packSaleVariant,
      

      // track
      trackRef,
      addTrack,
      removeTrack,

      // item
      packages,
      newPackage,
      addPackage,
      removePackage,

      tab,
      sidebarWidth,
      saleData
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/base/bootstrap-extended/_variables.scss';
html {
  overflow: hidden;
}
#sale-view-sidebar {
  transition: all 300ms;
  
  //Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }
  .b-sidebar-body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .text-size-150 {
    font-size: 1.5rem
  }
  .z-index-10 {
    z-index: 10
  }

  .form {
    span:empty:before {
      content: "\200b"; // unicode zero width space character
    }
  }

  .journal {
    table {
      td {
        padding: 0.25rem 1rem;
      }
      tr:first-child {
        td {
          padding-top: 0.5rem;
        }
      }
      tr:last-child {
        td {
          padding-bottom: 0.5rem;
        }
      }
    }
  }

  .reward {
    .q-chip__content {
      justify-content: space-between;
    }
    table {
      th {
        padding: 0.72rem 1.25rem;
      }

      td:nth-child(3){
        border-left: $secondary solid 1px;
      }

      td:nth-child(5){
        border-right: $secondary solid 1px;
      }
    }
  }
}
</style>