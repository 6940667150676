<template>
  <q-dialog id="pay-dialog" v-model="isPayDialogOpen" @hide="payDialog.cancel">
    <div class="mw-100">
      <q-form v-if="saleData && saleData.customer" class="bg-white rounded-lg" @submit="payDialog.confirm">
        <div class="p-1 p-md-2 list-view product-checkout d-flex flex-column flex-lg-row-reverse gap-3">
          <!-- Order details -->
          <div v-if="saleData" class="flex-size-4 flex-even overflow-hidden border rounded-lg bg-light-secondary">
            <q-item-label class="bg-primary text-white p-2 h4 font-weight-normal m-0">{{$t('supply-chain.sale.details')}}</q-item-label>
            <q-list class="p-2">
              <q-item dense><span>{{saleData.orderedAt | date(true)}}</span></q-item>
              <q-separator spaced />
              <q-item v-for="item in saleData.variants" :key="item._id">
                <q-item-section avatar>
                  <q-img contain :src="item.image" class="rounded"/>
                </q-item-section>
                <q-item-section>
                  <!-- product name -->
                  <h5 class="m-0">{{item.productNames.find(name=>name.locale === $i18n.locale).name}}</h5>
                  <!-- variant name -->
                  <span>{{item.names.find(name=>name.locale === $i18n.locale).name}}</span>
                </q-item-section>
                <q-item-section class="px-1">
                  <div class="text-nowrap">{{item.price | price($t(`currency.${item.currency}.sign`))}}</div>
                  <span class="text-nowrap">x {{item.quantity}}</span>
                </q-item-section>
                <q-item-section side no-wrap class=" m-0">
                  {{item.amount | price($t(`currency.${item.currency}.sign`))}}
                </q-item-section>
              </q-item>

              <!-- Coupon -->
              <q-item v-if="saleData.couponValue" dense class="d-flex justify-content-between align-items-center">
                <fa-icon :icon="['fad','ticket-alt']" size="2x" class="text-primary" />
                <span class="h5 m-0 text-success">- {{saleData.couponValue | price($t(`currency.${saleData.currency}.sign`))}}</span>
              </q-item>

              <!-- Tax -->
              <q-item v-if="saleData.taxable" dense class="d-flex justify-content-between align-items-center">
                <span>{{saleData.taxRate}}% {{$t('supply-chain.invoice.tax')}}</span>
                <span>{{saleData.taxAmount | price($t(`currency.${saleData.currency}.sign`))}}</span>
              </q-item>

              <q-separator spaced />

              <!-- Total -->
              <q-item dense class="d-flex justify-content-between align-items-center">
                <span>{{$t('supply-chain.invoice.total')}}</span>
                <span class="mb-0 h5">{{saleData.total | price($t(`currency.${saleData.customer.currency}.sign`))}}</span>
              </q-item>

              <!-- paid amount -->
              <template v-if="saleData.paidAmount">
                <q-item dense class="d-flex justify-content-between align-items-center">
                  <span>{{$t('supply-chain.payment.paid_amount')}}</span>
                  <span>- {{saleData.paidAmount | price($t(`currency.${saleData.currency}.sign`))}}</span>
                </q-item>
                <q-separator spaced />
                <q-item dense class="d-flex justify-content-between align-items-center">
                  <span>{{$t('supply-chain.invoice.balance')}}</span>
                  <span>{{saleData.total-(saleData.paidAmount || 0) | price($t(`currency.${saleData.currency}.sign`))}}</span>
                </q-item>
              </template>
            </q-list>
          </div>

          <!-- 付款 -->
          <div v-if="saleData && saleData.customer" class="flex-size-6 flex-even d-flex flex-column gap-3" style="min-width:300px">
            <q-list class="card overflow-hidden mb-0">
              <q-item-label class="bg-light-primary font-weight-bold text-white text-capitalize p-2 h4 font-weight-normal m-0">{{$t('supply-chain.payment._')}}</q-item-label>

              <q-item>
                <q-item-section avatar>
                  <q-avatar><img :src="fileUrl(saleData.customer.avatar, 'customer')"/></q-avatar>
                </q-item-section>
                <q-item-section>
                  <div class="text-bold">{{saleData.customer.name}}</div>
                  <div><q-chip size="sm" color="light-primary">{{saleData.customer.customerNo}}</q-chip></div>
                </q-item-section>
              </q-item>
              <q-separator />

              <!-- 使用余额 -->
              <q-item>
                <div class="h5 pt-1 w-100 d-flex justify-content-between align-items-center">
                  <span>{{$t('supply-chain.payment.balance')}}</span>
                  <span class="text-primary">{{saleData.customer.balance | price($t(`currency.${saleData.customer.currency}.sign`))}}</span>
                </div>
              </q-item>
              <q-item class="gap-1 align-items-center">
                <q-input filled v-model.number="payBalance" type="number" step="0.01" class="w-100 pb-0" input-class="flex-nowrap" :label="$t('supply-chain.payment.use_balance')"
                  :rules="[val=>(Boolean(!val)||val>=0) || $t('rule.positive'), val=>(Boolean(!val)||val<=saleData.customer.balance) || $t('rule.no_enough_balance')]">
                  <template v-slot:prepend>
                    <q-avatar>
                      <q-icon name="account_balance_wallet" size="sm" class="mr-1 text-primary"></q-icon>
                    </q-avatar>
                  </template>
                  <template v-slot:append>
                    <q-btn push no-caps color="primary" :disable="toNumber(saleData.total-(saleData.paidAmount||0)-payBalance-payCredit)===0"
                      @click="payBalance=Math.max(Math.min(saleData.customer.balance, saleData.total-(saleData.paidAmount||0)-payCredit),0)">{{$t('supply-chain.payment.use')}}
                    </q-btn>
                  </template>
                </q-input>
              </q-item>

              <q-separator />

              <!-- 使用 Credit -->
              <q-item>
                <div class="h5 m-0 pt-1 w-100 d-flex justify-content-between align-items-center">
                  <span>{{$t('supply-chain.payment.credit')}}</span>
                  <span class="text-warning">{{saleData.customer.credit | price($t(`currency.${saleData.customer.currency}.sign`))}}</span>
                </div>
              </q-item>
              <q-item>
                <q-input filled v-model.number="payCredit" type="number" step="0.01" class="w-100 p-0" input-class="flex-nowrap" :label="$t('supply-chain.payment.use_credit')"
                  :rules="[val=>(Boolean(!val)||val>=0) || $t('rule.positive'), val=>(Boolean(!val)||val<=saleData.customer.balance) || $t('rule.no_enough_credit')]">
                  <template v-slot:prepend><q-icon name="monetization_on" size="sm" class="mr-1 text-warning"></q-icon></template>
                  <template v-slot:append>
                    <q-btn push no-caps color="warning" :disable="toNumber(saleData.total-(saleData.paidAmount||0)-payBalance-payCredit)===0"
                      @click="payCredit=Math.max(Math.min(saleData.customer.credit, saleData.total-(saleData.paidAmount||0)-payBalance), 0)">
                      {{$t('supply-chain.payment.use')}}
                    </q-btn>
                  </template>
                </q-input>
              </q-item>

              <q-separator />

              <q-item>
                <div class="w-100">
                  <div class="w-100 test-right d-flex justify-content-between align-items-center">
                  <h4 class="m-0 text-capitalize">{{$t('form.total')}}</h4>
                  <h4 class="m-0">{{Math.abs(payBalance + payCredit) | price}}</h4>
                </div>
                <small class="w-100 text-danger text-right d-block" v-if="toNumber(saleData.total-(saleData.paidAmount||0)-payBalance-payCredit)!==0">
                  {{saleData.total-(saleData.paidAmount||0)-payBalance-payCredit|price}}
                </small> 
              </div>
              </q-item>
            </q-list>
          </div>
        </div>

        <div class="d-flex justify-content-between px-2 py-1 shadow-top bg-light-secondary gap-2">
          <q-btn type="submit" no-caps :disable="toNumber(saleData.total-(saleData.paidAmount||0)-payBalance-payCredit)!==0" push color="primary" :label="$t('action.save') | capitalize"/>
          <q-btn v-if="showClearButton" no-caps push color="warning" @click.stop="clear" :label="$t('action.clear') | capitalize"/>
          <q-btn push color="danger" no-caps v-close-popup :label="$t('action.cancel') | capitalize"/>
        </div>
      </q-form>
    </div>
  </q-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { price, date, capitalize, toNumber, fileUrl } from '@core/utils/filter'
import store from '@/store'
import customerStoreModule from '@/views/member/customer/customerStoreModule'
if (!store.hasModule('customer')) store.registerModule('customer', customerStoreModule)
// Notification
import { Toast } from '@core/utils/other'

export default {
  name:'pay-dialog',
  filters:{
    price, date, capitalize
  },
  props:{
    showClearButton:{
      type: Boolean,
      default: false
    }
  },
  setup(){
    const isPayDialogOpen = ref(false)
    const saleData = ref(null)

    const payBalance = ref(null)
    const payCredit = ref(null)

    watch(payBalance, val=>payBalance.value = toNumber(val))
    watch(payCredit, val=>payCredit.value = toNumber(val))

    class PayDialog {
      constructor (){}
      confirm(){}
      cancel(){}
      resetDialog(){
        saleData.value = null
        payBalance.value=null
        payCredit.value=null
      }

      openDialog = async ({saleId, customerId}) => {
        isPayDialogOpen.value=true

        const responses = await Promise.all([
          // get order info
          store.dispatch('sale/fetchSale', saleId),
          // get customer balance info
          store.dispatch('customer/fetchCustomer', customerId)
        ])

        saleData.value = responses[0].sale

        return new Promise((res, rej)=>{
          this.confirm = ()=>{
            store.dispatch('sale/paySale',{
              id: saleId,
              payload: {
                balance: payBalance.value,
                credit: payCredit.value
              }
            }).then(r=>{
              isPayDialogOpen.value=false
              res(true)
              this.resetDialog()
            }).catch(e=>Toast.fire({
              icon: 'error',
              title: e.message
            }))
          }
          this.cancel = () => {
            isPayDialogOpen.value=false
            res(false)
            this.resetDialog()
          }
        })
      }
    }

    const payDialog = new PayDialog()

    return {
      toNumber, fileUrl,
      saleData,

      payBalance,
      payCredit,

      isPayDialogOpen,
      payDialog,
      openDialog: payDialog.openDialog,
    }
  }
}
</script>

